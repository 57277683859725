@use "../../variable.scss";

.frame {
  .input {
    height: 40px;
    border-color: variable.$light-gray-02;
    border-width: 1px;
    border-radius: 8px;
    padding: 0 16px;
    outline: none;
    &:focus {
      border-color: variable.$light-blue-100;
    }
    &:hover {
      border-color: variable.$light-gray-01;
    }
  }
  .caption {
    color: variable.$gray;
  }
  &.error {
    .input {
      border-color: variable.$red-100;
    }
    .caption {
      color: variable.$red-100;
    }
  }
}