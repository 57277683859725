@use "../../variable.scss";

.button {
  color: variable.$white;
  background: variable.$light-blue-75;
  min-width: 160px;
  height: 48px;
  border-style: none;
  border-radius: 24px;
  font-size: 17px;
  line-height: 18.7px;
  padding: 0 16px;
  filter: drop-shadow(0 4px 0 variable.$light-blue-100);
  &:hover {
    background-color: variable.$light-blue-50;
  }
  &:disabled {
    opacity: 0.3;
  }
}

.red {
  background: variable.$red-75;
  filter: drop-shadow(0px 4px 0px variable.$red-100);
  &:hover {
    background-color: variable.$red-50;
  }
}

.gray {
  background: variable.$light-gray-03;
  filter: drop-shadow(0px 4px 0px variable.$gray);
  &:hover {
    background-color: variable.$light-gray-02;
  }
}
