$background-color: #f8f8f8;
$active-menu-icon-color: #c3e8f5;
$active-menu-text-color: #00a7db;

.frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 256px;
  min-width: 256px;
  height: 100vh;
  position: relative;
  background: $background-color;
  .logo {
    margin: 32px 0;
  }
  .i18n-area {
    position: absolute;
    left: 16px;
    bottom: 80px;
  }
  .user-info {
    position: absolute;
    left: 16px;
    bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: calc(100% - 32px);
  margin: 4px 8px;
  padding: 4px 8px;
  cursor: pointer;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    margin-left: 8px;
  }
}

.menu-item-active {
  background: $active-menu-icon-color;
  border-radius: 8px;
  .text {
    color: $active-menu-text-color;
  }
}

.frame-small {
  width: 56px;
  height: 100vh;
  background: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: calc(100% - 16px);
    margin: 4px 8px;
    cursor: pointer;
  }
  .icon-active {
    background: $active-menu-icon-color;
    border-radius: 8px;
  }
}
