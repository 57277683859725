/** Color */
$light-blue-100: #00A7DB;
$light-blue-75: #40BDE4;
$light-blue-50: #88D2EC;
$light-blue-25: #C3E8F5;
$light-blue-10: #E5F6FB;

$yellow-100: #F3DF23;
$yellow-75: #F6E75A;
$yellow-75: #F9EF91;
$yellow-75: #FCF7C8;
$yellow-75: #FEFCE9;

$purple-100: #7B00DB;
$purple-100: #9C40E4;
$purple-100: #BD80ED;
$purple-100: #DEBFF6;

$red-100: #F15353;
$red-75: #F47E7E;
$red-50: #F8A9A9;
$red-25: #FDE9E9;

$green-100: #34CC43;
$green-75: #67D972;
$green-50: #95DB95;
$green-25: #CAEDCA;

$black-100: #231815;
$light-black: #322B29;
$dark-gray: #575453;
$gray: #8D8D8D;
$light-gray-03: #B8B8B8;
$light-gray-02: #DCDCDC;
$light-gray-01: #F2F3F3;
$off-white: #F8F8F8;
$white: #FFFFFF;
$alice-blue-100: #F1FAFD;

/** Font */
$font-family: dnp-shuei-mgothic-std, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
