@use "./variable.scss";

body {
  margin: 0;
  font-family: variable.$font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  line-height: 18.7px;
  font-weight: 400;
  color: variable.$black-100;
}

.frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.main {
  width: calc(100vw - 256px);
  height: 100vh;
  overflow-y: scroll;
  padding: 0 32px;
  .page-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 44px;
    margin: 48px 0 28px;
  }
  .bar {
    width: 100%;
    height: 1px;
    background-color: variable.$light-gray-01;
  }
  .contents {
    margin: 32px 0;
  }
}

@mixin base-font {
  font-family: variable.$font-family;
  font-style: normal;
  color: variable.$black-100;
}
@mixin heading {
  @include base-font;
  font-weight: 600;
}
.heading-1 {
  @include heading;
  font-size: 56px;
  line-height: 63px;
}
.heading-2 {
  @include heading;
  font-size: 48px;
  line-height: 53px;
}
.heading-3 {
  @include heading;
  font-size: 40px;
  line-height: 44px;
}
.heading-4 {
  @include heading;
  font-size: 32px;
  line-height: 35px;
}
.heading-5 {
  @include heading;
  font-size: 24px;
  line-height: 26px;
}
.heading-6 {
  @include heading;
  font-size: 20px;
  line-height: 22px;
}

@mixin body-title {
  @include base-font;
  font-size: 20px;
  line-height: 28px;
}
.b-body-title {
  @include body-title;
  font-weight: 600;
}
.l-body-title {
  @include body-title;
  font-weight: 400;
}

@mixin body-text-large {
  @include base-font;
  font-size: 18px;
  line-height: 25px;
}
.b-body-text-large {
  @include body-text-large;
  font-weight: 600;
}
.l-body-text-large {
  @include body-text-large;
  font-weight: 400;
}

@mixin body-text {
  @include base-font;
  font-size: 17px;
  line-height: 19px;
}
.b-body-text {
  @include body-text;
  font-weight: 600;
}
.l-body-text {
  @include body-text;
  font-weight: 400;
}

@mixin body-text-small {
  @include base-font;
  font-size: 16px;
  line-height: 22px;
}
.b-body-text-small {
  @include body-text-small;
  font-weight: 600;
}
.l-body-text-small {
  @include body-text-small;
  font-weight: 400;
}

@mixin small-text {
  @include base-font;
  font-size: 14px;
  line-height: 20px;
}
.b-small-text {
  @include small-text;
  font-weight: 600;
}
.l-small-text {
  @include small-text;
  font-weight: 400;
}

@mixin caption-text {
  @include base-font;
  font-size: 12px;
  line-height: 17px;
}
.b-caption-text {
  @include caption-text;
  font-weight: 600;
}
.l-caption-text {
  @include caption-text;
  font-weight: 400;
}

@mixin caption-text-2 {
  @include base-font;
  font-size: 10px;
  line-height: 14px;
}
.b-caption-text-2 {
  @include caption-text-2;
  font-weight: 600;
}
.l-caption-text-2 {
  @include caption-text-2;
  font-weight: 400;
}

.flex-row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-between-start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-row-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.margin-left-8 {
  margin-left: 8px;
}
.margin-left-16 {
  margin-left: 16px;
}
.margin-left-32 {
  margin-left: 32px;
}
.margin-left-48 {
  margin-left: 48px;
}
